import React from 'react'
import { useHistory } from 'react-router'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CLink, CTooltip } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import userViewAdapter from 'commons/adapters/userViewAdapter'
import useLoggedUser from 'templates/context/session-state/useLoggedUser'
import Avatar from 'components/avatar'
import Button from 'components/form/button'
import useRoute from 'commons/helpers/route/useRoute'

const ProfileNav: React.FC = () => {
  const history = useHistory()
  const { loggedUser } = useLoggedUser()
  const user = userViewAdapter(loggedUser)

  const { routeAction, routeTab } = useRoute()

  const handleUpdate = (): void => {
    history.push(routeTab({ path: (makeRoute({ path: routePaths.USER, params: { slug: loggedUser?.id } })), tab: 'identification' }))

    history.push(routeAction('edit-image'))
  }

  return (
    <CDropdown inNav className="c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar border align-items-stretch">
          <Avatar picture={user.getPicture()} alt={user.getName()} />
        </div>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end">
        <div className="mx-5 my-2" style={{ textAlign: 'center' }}>
          <div className="c-avatar border " style={{ width: '65px', height: '65px' }}>
            <CTooltip content="Perfil do Usuário">
              <CLink onClick={handleUpdate} style={{ width: '65px', height: '65px' }}>
                <Avatar
                  picture={user.getPicture()}
                  alt={user.getName()}
                  style={{ width: '65px', height: '65px' }}
                />
              </CLink>
            </CTooltip>
          </div>
          <div>
            <h5 className="mt-2 mb-0">{user.getName()}</h5>
            <p>{user.getEmail()}</p>
            <Button
              to={makeRoute({ path: routePaths.USER, params: { slug: loggedUser.id } })}
              className="mb-2 shadow-none border rounded-pill"
              title="Gerenciar meu Perfil"
            />
          </div>
        </div>
        <CDropdownItem divider />
        <div style={{ textAlign: 'center' }}>
          <Button
            to={makeRoute({ path: routePaths.SIGN_OUT })}
            className="shadow-none border pl-3 pr-3"
            title="Sair"
          />
        </div>
        <CDropdownItem divider />
        <div className="d-flex justify-content-center small mt-3 mb-1 ml-2">
          <a href="https://contabilone.com/document-use-terms-cone.pdf" target="_blank" rel="noreferrer" className="mr-2">Termos de Uso</a>
          {' • '}
          <a href="https://contabilone.com/document-privacity-policy-cone.pdf" target="_blank" rel="noreferrer" className="ml-2">Política de Privacidade</a>
        </div>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default ProfileNav
